.promotion-container{
  display: flex;
  border: 1px solid #aaa;
  padding: 32px;
  border-radius: 4px;
}

.promotion-joinus{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  padding: 16px;
}

.promotion-joinus h3{
  font-size: 28px;
  font-weight: bold;
}
.promotion-joinus p{
text-align: center;
}

.promotion-joinus .msdcall {
  text-decoration: none;
  display: block;
  text-align: center;
  font-size: 32px;
  color: green;
  font-weight: bold;
  margin: 0 0 5px;
  padding: 0;
}

 .promotion-donation{
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.promotion-donation p{
  text-align: center;

}
.promotion-valunteer{
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}
.promotion-valunteer p{
  text-align: center;
}