.banner-container{
  display: flex;
}
.banner-content{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #030180;
  padding-left: 24px;
}
.banner-label{
  color:#fea500;
  margin: 0;
  line-height: 2.75;
  text-transform: uppercase;
}
.banner-title{
  color:white;
  margin: 0;
  line-height: 1.2;
}
.contact-container{
  width:50%;
  background-color: white;
}
.contact-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 240px;
  padding-left: 24px;
}
.contact-title{
  margin: 0;
  line-height: 1.5;
  color:#030180;
}
.contact-link{
  margin: 0;
  line-height: 3.0;
  text-transform: uppercase;
  font-weight: 700;
  color:rgba(0,0,0,0.65);
  font-size:14px;
}
.quick-links-container{
  display: flex;
}
.party-logo-container{
  width: 50%;
  height: 180px;
  background-color: #fea500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.party-logo-container img{
  height: 148px;
}
.media-links{
  width: 50%;
  background-color: #030180;
}
.media-links ul{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  margin: 0;
}
.media-links li{
  list-style-type: none;
  margin: 0 5px;
  color:white;
}