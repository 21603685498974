.banner-container {
  display: flex;
}

.banner-content {
  background-color: #030180;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding-left: 24px;
  display: flex;
}

.banner-label {
  color: #fea500;
  text-transform: uppercase;
  margin: 0;
  line-height: 2.75;
}

.banner-title {
  color: #fff;
  margin: 0;
  line-height: 1.2;
}

.contact-container {
  background-color: #fff;
  width: 50%;
}

.contact-content {
  flex-direction: column;
  justify-content: center;
  height: 240px;
  padding-left: 24px;
  display: flex;
}

.contact-title {
  color: #030180;
  margin: 0;
  line-height: 1.5;
}

.contact-link {
  text-transform: uppercase;
  color: #000000a6;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 3;
}

.quick-links-container {
  display: flex;
}

.party-logo-container {
  background-color: #fea500;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 180px;
  display: flex;
}

.party-logo-container img {
  height: 148px;
}

.media-links {
  background-color: #030180;
  width: 50%;
}

.media-links ul {
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  margin: 0;
  display: flex;
}

.media-links li {
  color: #fff;
  margin: 0 5px;
  list-style-type: none;
}

.header {
  z-index: 1;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  display: flex;
  position: sticky;
  top: 0;
  box-shadow: 0 4px 8px #0003;
}

.logo {
  width: 76px;
  margin-top: 8px;
  padding: 0 20px;
}

.nav-items {
  align-items: center;
  height: 100%;
  display: flex;
}

.nav-items ul {
  align-items: center;
  display: flex;
}

.nav-items li {
  padding: 0 15px;
  font-size: 14px;
  list-style-type: none;
}

.nav-items li:hover {
  color: #fea500;
  cursor: pointer;
}

.contact-nav-item {
  background-color: #030180;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 36px;
  display: flex;
}

.contact-nav-item a {
  color: #fff;
  font-size: 14px;
}

.content-container {
  width: 100%;
}

.content-container .content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.content-img {
  width: 100%;
}

.content-img img {
  width: 100%;
  height: 128px;
}

.content {
  width: 100%;
  height: 240px;
}

.content-title {
  color: #030180;
  margin: 0;
  line-height: 2.75;
}

.content-description {
  text-align: center;
  margin: 0;
}

.manifesto-wrap {
  animation: 6s ease-in-out infinite alternate anime1;
  display: flex;
  position: relative;
}

.manifesto-item {
  color: #fff;
  background-color: #07b817;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 168px;
  margin: 4px 2px 0;
  font-size: 20px;
  font-weight: 600;
  display: flex;
}

@keyframes MyDiv {
  0% {
    top: 0;
    left: -50px;
  }

  100% {
    top: 0;
    left: 0;
  }
}

@keyframes anime1 {
  0% {
    transform: translateX(-52%);
  }

  100% {
    transform: translateX(0);
  }
}

.container {
  width: 1140px;
  margin: 0 auto;
}

html, body {
  box-sizing: border-box;
  margin: 0;
  font-family: Poppins;
}

.promotion-container {
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 32px;
  display: flex;
}

.promotion-joinus {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  padding: 16px;
  display: flex;
}

.promotion-joinus h3 {
  font-size: 28px;
  font-weight: bold;
}

.promotion-joinus p {
  text-align: center;
}

.promotion-joinus .msdcall {
  text-align: center;
  color: green;
  margin: 0 0 5px;
  padding: 0;
  font-size: 32px;
  font-weight: bold;
  text-decoration: none;
  display: block;
}

.promotion-donation {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 16px;
  display: flex;
}

.promotion-donation p {
  text-align: center;
}

.promotion-valunteer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 16px;
  display: flex;
}

.promotion-valunteer p {
  text-align: center;
}

.footer-container {
  justify-content: space-between;
  display: flex;
}

.footer-contact, .footer-quick-links, .footer-media {
  width: 30%;
  padding-right: 24px;
}

.footer-quick-links ul, .footer-media ul {
  padding-left: 0;
  list-style: none;
}

.footer-quick-links, .footer-media {
  flex-direction: column;
  align-items: center;
  display: flex;
}

/*# sourceMappingURL=index.2ddcf438.css.map */
