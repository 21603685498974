.header{
  display:flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  position: sticky;
  height: 64px;
  position: sticky;
  top:0px;
  background-color: white;
}
.logo{
  width: 76px;
  padding: 0px 20px;
  margin-top:8px ;
}
.nav-items{
  display: flex;
  align-items: center;
  height: 100%;
}
.nav-items ul{
  display: flex;
  align-items: center;
}
.nav-items li{
  list-style-type: none;
  padding: 0px 15px;
  font-size: 14px;
}
.nav-items li:hover{
  color:#fea500;
  cursor: pointer;
}
.contact-nav-item{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #030180;
  padding: 0px 36px;
}
.contact-nav-item a{
  color: white;
  font-size: 14px;
}