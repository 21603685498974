.content-container{
  width: 100%;
}
.content-container .content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content-img{
  width: 100%;
}
.content-img img{
  width: 100%;
  height: 128px;
}
.content{
  height: 240px;
  width: 100%;
}
.content-title{
  margin: 0;
  color: #030180;
  line-height: 2.75;
}
.content-description{
  margin: 0;
  text-align: center;
}